html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.homeimg {
  width: 50vh;
  float: left;
}

.divHome h2 {
  font-size: 30pt;
}

.divHome {
  padding-top: 50px;
  float: left;
  text-align: left;
}

* {
  box-sizing: border-box;
}

#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}


#menuleft-2 {
  position: relative;
  float: left;
  width: 20%;
  display: block;
}

#rowcontent {
  display: flex;
  flex-direction: row;
}

.lefty {
  float: left;
  width: 100px;
  background-color: rgb(224, 224, 224);
  height: 40px;
  padding: 8px;
  color: black
}

.righty {
  float: left;
  border-left: 2px solid #1890ff;
  border-bottom: 2px solid #1890ff;
  width: calc(100% - 100px);
  padding-left: 20px;
  padding-top: 8px;
}

.container_orderlines {
  width: 100%;
  overflow: auto;
  margin-bottom: 15px;

}

.orderlines {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container_orderlines:hover {
  background-color: rgb(236, 236, 236);
}

.orderlines div {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 200px;
}