.Landing {
  text-align: center;
}

.logo {
  position: absolute;
  top: 0px;
  left: 35px;
  color: white;
}

/* DOCUMENTATION */
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #e7e7e8;
  border: 0px;
  border-radius: 2px;
}

/* LOGIN */
.form-login {
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* SEARCH */
.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.searchInputs {
  display: flex;
}

.numberSearch {
  font-size: 16px;
  text-align: center;
}

.wordSearch {
  text-align: center;
  font-size: 14px;
  color: #282c34
}

.search input {
  background-color: #e9e7e7;
  border: 0;

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  font-size: 18px;
  padding: 15px;
  height: 40px;
  width: 250px;
}

.searchIcon {
  height: 40px;
  width: 40px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #e9e7e7;
  color: white;
  place-items: center;
  display: grid;
}

.searchIcon svg {
  font-size: 35px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 5px;
  color: #282c34
}

input:focus {
  outline: none;
}

input::placeholder {
  color: #282c34;
}

#loginbutton {
  position: absolute;
  top: 0px;
  right: 15px;
}

.spin {
  width: 100%;
  margin-top: 50px !important;
  font-size: 80px;
  margin: 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dataResult {
  margin-top: 0px;
  width: 100%;
  height: 480px;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .itemResult {
  width: 100%;
  height: 50px;
  color: black;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #282c3413;
}

.referenceItem {
  width: 160px;
  text-align: center;
  font-size: 18px;
}

.descriptionItem {
  width: 240px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
}

.description_longItem {
  max-width: 370px;
  min-width: 350px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
}

.contactItem {
  width: 220px;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
}

.statusItem {
  width: 150px;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
}

.itemResult p {
  margin: 0 auto;
}

.itemResult:hover {
  background-color: #f3f3f3;
}

.itemInkoPrint {
  color: #2f549e !important;

}

.notFoundOrder {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.notFoundOrder p {
  margin-top: 18px;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
}

.titleBar {
  margin-top: 20px;
  height: 35px;
  width: 100%;
  padding-top: 15px;

  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;

  display: flex;
  justify-content: space-around;
  align-items: center;

  background-color: #282c34;

  color: white !important;

}

/* SEARCH IN THE SIDEBAR */
.input-sidebar {
  margin: 10px 0px !important;
  padding: 25px 15px 15px 15px !important;
  background-color: white !important;
}

/* CALENDAR */
.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}

/* FINISH CALENDAR */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.error-msg {
  border: 1px solid red;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.error-alarm {
  position: fixed !important;
  z-index: 100;
  right: 50%;
  transform: translateX(50%);
  bottom: 30px;
  min-width: 400px;
}

.inko-profile-header {
  display: flex;
  align-items: flex-end;
  border-top: 1px solid rgba(128, 128, 128, 0.466);
  padding: 10px 0px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.title-profile {
  margin-left: 5px;
}

.border {
  border: 1px solid red;
}

.border1 {
  border-bottom: 1px solid black;
}

.btn-header {
  position: absolute;
  margin-top: 15px;
  float: right;
}

.avatar-btn {
  margin-right: 10px !important;
  margin-bottom: 3px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}